<template>
  <div id="app">
    <div class="loader d-flex align-items-center" v-if="loading">
      <div class="spinner1">
        <div class="spinner1 spinner2">
          <div class="spinner1 spinner3"></div>
        </div>
      </div>
    </div>
    <div id="adfox_173072488177579567" style="z-index: 1111 !important"></div>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      isToken: false,
      showModal: false,
    };
  },
  mounted() {
    console.log("mounted testded 6:38");
    window.yaContextCb.push(() => {
      Ya.adfoxCode.createAdaptive(
        {
          ownerId: 11637381,
          containerId: "adfox_173072488177579567",
          params: {
            p1: "detrs",
            p2: "jfzz",
          },
        },
        ["desktop", "tablet", "phone"],
        {
          tabletWidth: 830,
          phoneWidth: 480,
          isAutoReloads: false,
        }
      );
    });
    setTimeout(() => {
      this.loading = false;
      this.isToken = localStorage.getItem("user_info");
      if (!this.isToken) {
        this.showModal = true;
      }
    }, 600);
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss">
.loader {
  width: 100%;
  height: 100vh !important;
  background-color: white;
}

.spinner1 {
  margin: auto;
  height: 250px;
  width: 250px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #5168a0;
  border-bottom: none;
  border-left: none;
  animation: spin 4.5s infinite linear reverse;

  .spinner2 {
    margin: 25px auto;
    height: 200px;
    width: 200px;
    animation: spin 2s infinite linear;
  }

  .spinner3 {
    margin: 25px auto;
    height: 150px;
    width: 150px;
    animation: spin 1s infinite linear reverse;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.v-toast
  .v-toast--top
  .v-toast__item
  .v-toast__item--error
  .v-toast__item--top {
  z-index: 999999 !important;
}
alert {
  z-index: 999999 !important;
}
</style>
